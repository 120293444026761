/*
Template Name: Velonic - Responsive Bootstrap 5 Admin Dashboard
Author: Techzaa
File: Quilljs init js
*/


// Bubble theme
var quill = new Quill('#bubble-editor', {
    theme: 'bubble'
});