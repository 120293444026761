var icons = {
    "Arrows": {
        "arrow-left-up": "corner,左上",
        "arrow-up": "send,箭头,向上,发送",
        "arrow-right-up": "send,corner,右上,发送",
        "arrow-right": "forward,箭头,向右",
        "arrow-right-down": "corner,右下",
        "arrow-down": "receive,箭头,向下,接收,收款",
        "arrow-left-down": "receive,corner,箭头,左下,接收,收款",
        "arrow-left": "backward,箭头,向左,返回",
        "corner-right-up": "arrow,箭头,右上",
        "corner-up-left": "arrow,箭头,上左",
        "corner-up-right": "arrow,箭头,上右",
        "corner-right-down": "arrow,箭头,右下",
        "corner-down-left": "arrow,箭头,下左",
        "corner-left-up": "arrow,箭头,左上",
        "corner-left-down": "arrow,箭头,左下",
        "corner-down-right": "arrow,箭头,下右",
        "arrow-up-circle": "箭头,向上",
        "arrow-right-circle": "forward,箭头,向右",
        "arrow-down-circle": "箭头,向下,下载",
        "arrow-left-circle": "backward,箭头,向左,返回",
        "arrow-up-s": "chevron,箭头,向上",
        "arrow-right-s": "chevron,forward,箭头,向右",
        "arrow-down-s": "chevron,箭头,向下",
        "arrow-left-s": "chevron,backward,箭头,向左,返回",
        "skip-up": "arrow,unfold,top,置顶,展开,收起",
        "skip-right": "arrow,unfold,next,跳过,展开,收起",
        "skip-down": "arrow,unfold,next,跳过,展开,收起",
        "skip-left": "arrow,unfold,bottom,展开,收起",
        "arrow-left-right": "exchange,swap,箭头,左右,交换,换算,兑换",
        "arrow-up-down": "exchange,swap,箭头,上下,交换,换算,兑换",
        "arrow-drop-up": "chevron,箭头,向上",
        "arrow-drop-right": "chevron,forward,箭头,向右",
        "arrow-drop-down": "chevron,箭头,向下",
        "arrow-drop-left": "chevron,backward,箭头,向左,返回",
        "arrow-up-double": "unfold,top,chevron,展开,收起",
        "arrow-right-double": "unfold,chevron,展开,收起",
        "arrow-down-double": "unfold,chevron,展开,收起",
        "arrow-left-double": "unfold,chevron,展开,收起",
        "corner-up-left-double": "arrow,replay-all,回复全部",
        "corner-up-right-double": "arrow,forward-all,转发全部",
        "expand-left": "slide, pull, unfold,slide, pull, unfold,arrow,slide,pull,unfold",
        "expand-right": "arrow,展开,收起,右",
        "contract-left": "slide, pull, fold,slide, pull, fold,arrow,slide,pull,fold",
        "contract-right": "arrow,slide,pull,fold,展开,收起,右",
        "expand-up-down": "unfold, dropdown, switch,unfold, dropdown, switch,arrow,unfold,dropdown,switch,展开",
        "expand-left-right": "unfold, dropdown, switch,unfold, dropdown, switch,arrow,unfold,dropdown,switch",
        "contract-up-down": "fold,fold,arrow,fold,收起",
        "contract-left-right": "arrow,fold,收起",
        "drag-move-2": "arrow,拖拽,移动,箭头",
        "drag-move": "arrow,拖拽,移动,箭头",
        "arrow-go-back": "undo,箭头,返回,撤销,撤回",
        "arrow-go-forward": "redo,箭头,重做,撤销,撤回",
        "arrow-turn-forward": "箭头,掉头",
        "arrow-turn-back": "箭头,掉头"
    },
    "Buildings": {
        "home": "house,房子,家,主页",
        "home-2": "house,房子,家,主页",
        "home-3": "house,房子,家,主页",
        "home-4": "house,房子,家,主页",
        "home-5": "house,房子,家,主页",
        "home-6": "house,房子,家,主页",
        "home-7": "house,房子,家,主页",
        "home-8": "house,房子,家,主页",
        "home-gear": "house,房子,工厂",
        "home-wifi": "smart home,房子,家具,智能家居",
        "home-smile": "house,smart home,smile,房子,智能家居,微笑",
        "home-smile-2": "house,smart home,smile,房子,智能家居,微笑",
        "home-heart": "house,心,房子,家,主页,孤儿院",
        "home-office": "居家办公",
        "building": "city,office,enterprise,建筑,城市,楼,办公楼,写字楼,企业",
        "building-2": "city,office,construction,enterprise,城市,建筑,楼,企业",
        "building-3": "factory,plant,enterprise,工厂,建筑,楼,企业",
        "building-4": "city,office,enterprise,建筑,城市,楼,办公楼,写字楼,企业",
        "hotel": "building,hotel,office,enterprise,tavern,建筑,酒店,楼,办公楼,写字楼,企业",
        "community": "building,hotel,社区,建筑,酒店",
        "school": "college,university,学校,学院",
        "government": "building,政府,建筑,大会堂",
        "bank": "bank,finance,savings,banking,银行,交易所",
        "store": "shop,mall,supermarket,retail,商店,超市,店铺,商家",
        "store-2": "shop,mall,supermarket,retail,商店,超市,店铺,商家",
        "store-3": "shop,mall,supermarket,retail,商店,超市,店铺,商家",
        "hospital": "medical,health,医院",
        "ancient-gate": "historical,genre,scenic,trip,travel,旅行,旅游,城门,古代,历史,景区",
        "ancient-pavilion": "historical,genre,scenic,trip,travel,旅行,旅游,凉亭,古代,历史,景区"
    },
    "Business": {
        "mail": "envelope,email,inbox,信封,邮箱,邮件,收件箱",
        "mail-open": "envelope,email,inbox,信封,邮箱,邮件,收件箱",
        "mail-send": "envelope,email,inbox,zoom,whoosh,信封,邮箱,邮件,发送,发件箱",
        "mail-unread": "envelope,email,inbox,信封,邮箱,邮件,未读",
        "mail-add": "envelope,email,inbox,add,信封,邮箱,邮件,新增,添加",
        "mail-check": "envelope,email,inbox,read,信封,邮箱,邮件,已读",
        "mail-close": "envelope,email,inbox,failed,x,信封,邮箱,邮件,失败",
        "mail-download": "envelope,email,inbox,download,信封,邮箱,邮件,下载",
        "mail-forbid": "envelope,email,inbox,privacy,信封,邮箱,邮件,禁止",
        "mail-lock": "envelope,email,inbox,lock,信封,邮箱,邮件,加密",
        "mail-settings": "envelope,email,inbox,settings,信封,邮箱,邮件,设置",
        "mail-star": "envelope,email,inbox,favorite,信封,邮箱,邮件,收藏,喜欢",
        "mail-volume": "envelope,email,inbox,promotional email,email campaign,subscription,sound,信封,邮箱,邮件,收件箱,推广,订阅",
        "inbox": "收件箱",
        "inbox-archive": "收件箱,归档,收纳",
        "inbox-unarchive": "unzip,unpack,extract,收件箱,取消归档,还原,解压缩",
        "cloud": "weather,云端",
        "cloud-off": "offline-mode,connection-fail,slash,weather,云端,断网,无信号,连接失败",
        "attachment": "annex,paperclip,附件,曲别针",
        "profile": "id,档案,资料,身份证,证件",
        "archive": "box,收纳,归档,存档,盒子,纸箱",
        "archive-drawer": "night table,收纳,抽屉,归档,存档,床头柜",
        "at": "@,mention,提到,在",
        "award": "medal,achievement,badge,成就,奖牌,金牌,勋章",
        "medal": "award,achievement,badge,成就,奖牌,金牌,勋章",
        "medal-2": "award,achievement,badge,成就,奖牌,金牌,勋章",
        "bar-chart": "statistics,rhythm,柱状图,统计,韵律,节奏",
        "bar-chart-horizontal": "statistics,rhythm,柱状图,统计,韵律,节奏",
        "bar-chart-2": "statistics,rhythm,柱状图,统计,排行,节奏",
        "bar-chart-box": "statistics,rhythm,柱状图,统计,节奏",
        "bar-chart-grouped": "statistics,rhythm,柱状图,统计,分组",
        "bubble-chart": "data,analysis,statistics,circles,气泡图,统计",
        "pie-chart": "data,analysis,饼图,饼状图,数据,分析",
        "pie-chart-2": "data,analysis,饼图,饼状图,数据,分析",
        "pie-chart-box": "data,analysis,饼图,饼状图,数据,分析",
        "donut-chart": "data,analysis,circle,pie,环形图,数据,分析",
        "line-chart": "data,analysis,stats,折线图,数据,分析",
        "bookmark": "tag,书签,标记",
        "bookmark-2": "tag,书签,标记",
        "bookmark-3": "tag,书签,标记,荣誉",
        "briefcase": "bag,baggage,公文包,行李箱,旅行箱,皮包",
        "briefcase-2": "bag,baggage,公文包,行李箱,旅行箱,皮包",
        "briefcase-3": "bag,baggage,公文包,行李箱,旅行箱,皮包",
        "briefcase-4": "bag,baggage,公文包,行李箱,旅行箱,皮包",
        "briefcase-5": "bag,baggage,公文包,行李箱,旅行箱,皮包",
        "calculator": "计算器,计算机",
        "calendar": "date,plan,schedule,agenda,日历,日期,月份,计划,日程,时间表",
        "calendar-2": "date,plan,schedule,agenda,日历,日期,月份,计划,日程,时间表",
        "calendar-event": "date,plan,schedule,agenda,日历,日期,月份,计划,日程,时间表",
        "calendar-todo": "date,plan,schedule,agenda,日历,日期,月份,计划,日程,时间表",
        "calendar-check": "date,plan,schedule,agenda,check-in,punch,日历,日期,月份,计划,日程,时间表,签到,打卡",
        "calendar-close": "date,plan,schedule,agenda,error,cancel,日历,日期,月份,计划,日程,时间表,取消,错误",
        "customer-service": "headset,headphones,earphones,客服,售后,耳机,耳麦",
        "customer-service-2": "headset,headphones,earphones,smile,客服,售后,耳机,耳麦",
        "flag": "banner,pin,旗帜,旗子,国旗,标记",
        "flag-2": "banner,pin,旗帜,旗子,国旗,标记",
        "global": "earth,union,world,language,地球,联合,世界,全球,语言",
        "honour": "honor,glory,锦旗,荣誉,荣耀,军衔",
        "links": "connection,address,联系,链接,地址",
        "printer": "打印机",
        "printer-cloud": "network,打印机,云打印",
        "record-mail": "voice mail,tape,录音,留言,语音信箱,磁带",
        "reply": "forward,回复全部,回复所有",
        "reply-all": "forward,回复,答复,留言,转发",
        "send-plane": "发送,纸飞机",
        "send-plane-2": "发送,纸飞机",
        "projector": "projection,meeting,投影仪,会议室",
        "projector-2": "projection,meeting,投影仪,会议室,极米",
        "slideshow": "presentation,meeting,PPT,keynote,投影,放映,演示,演讲,幻灯片,会议室",
        "slideshow-2": "presentation,meeting,投影,放映,演示,演讲,幻灯片,会议室",
        "slideshow-3": "presentation,meeting,投影,放映,演示,演讲,视频会议,幻灯片,会议室",
        "slideshow-4": "presentation,meeting,投影,放映,演示,演讲,可视对讲,幻灯片,会议室",
        "presentation": "teaching, training,teaching, training,teaching,training,演讲,老师,培训",
        "window": "browser,program,web,窗口,浏览器,程序,网站",
        "window-2": "browser,program,web,窗口,浏览器,程序,网站",
        "stack": "layers,图层,叠加,堆栈",
        "service": "heart,handshake,cooperation,client,服务,握手,心,合作",
        "registered": "注册,商标",
        "trademark": "注册,商标",
        "advertisement": "ad,广告,推广",
        "copyleft": "著佐权",
        "copyright": "版权",
        "creative-commons": "知识共享",
        "creative-commons-by": "attribution,copyright,版权,知识共享,署名",
        "creative-commons-nc": "noncommercial,copyright,版权,知识共享,非商业用途",
        "creative-commons-nd": "no derivative works,copyright,版权,知识共享,禁止演绎",
        "creative-commons-sa": "share alike,copyright,版权,知识共享,相同方式共享",
        "creative-commons-zero": "cc0,copyright,版权,知识共享",
        "megaphone": "bullhorn,loudspeaker,commercial,campaign,扩音器,喇叭,广告,活动",
        "pass-valid": "passport,护照有效",
        "pass-pending": "passport,护照等待",
        "pass-expired": "passport,护照过期",
        "seo": "搜索引擎优化"
    },
    "Communication": {
        "chat-1": "message,reply,comment,消息,聊天,回复,评论",
        "chat-2": "message,reply,comment,消息,聊天,回复,评论",
        "chat-3": "message,reply,comment,消息,聊天,回复,评论",
        "chat-4": "message,reply,comment,消息,聊天,回复,评论",
        "message": "chat,comment,reply,消息,聊天,回复,评论",
        "message-2": "chat,reply,comment,消息,聊天,回复,评论",
        "message-3": "chat,reply,comment,消息,聊天,回复,评论",
        "chat-check": "message,reply,comment,消息,聊天,回复,评论,已阅",
        "chat-delete": "message,comment,消息,聊天,回复,评论,清除,删除",
        "chat-forward": "message,comment,消息,聊天,转发",
        "chat-upload": "message,comment,消息,聊天,上传",
        "chat-download": "message,comment,消息,下载",
        "chat-new": "message,reply,comment,消息,聊天,回复,评论",
        "chat-settings": "message,comment,消息,聊天,回复,评论,设置",
        "chat-smile": "message,reply,comment,消息,聊天,回复,评论",
        "chat-smile-2": "message,reply,comment,消息,聊天,回复,评论",
        "chat-smile-3": "message,reply,comment,消息,聊天,回复,评论",
        "chat-heart": "message,reply,comment,消息,聊天,回复,评论,心,点赞,收藏",
        "chat-off": "message,reply,comment,slash,消息,聊天,回复,评论,禁止,关闭",
        "feedback": "message,comment,消息,聊天,回复,评论,反馈",
        "discuss": "message,reply,comment,消息,聊天,回复,评论,讨论,群聊",
        "question-answer": "message,reply,comment,消息,聊天,回复,评论,讨论,群聊",
        "questionnaire": "message,comment,help,消息,聊天,回复,评论,讨论,调查问卷,帮助",
        "video-chat": "message,comment,消息,视频聊天",
        "chat-voice": "message,comment,消息,语音消息",
        "chat-quote": "message,reply,comment,消息,引用回复",
        "chat-follow-up": "message,reply,comment,消息,+1,跟帖",
        "chat-poll": "message,vote,questionnaire,消息,投票,问卷调查",
        "chat-history": "message,历史消息,消息记录",
        "chat-private": "message,私密消息,密聊",
        "speak": "voice,讲话,声音",
        "emoji-sticker": "表情,贴纸"
    },
    "Design": {
        "pencil": "edit,铅笔,编辑",
        "edit": "pencil,铅笔,编辑",
        "edit-2": "pencil,铅笔,编辑",
        "ball-pen": "圆珠笔",
        "quill-pen": "羽毛笔,钢笔,编辑",
        "pen-nib": "钢笔,笔尖",
        "ink-bottle": "墨水瓶",
        "mark-pen": "马克笔",
        "markup": "标记,马克",
        "edit-box": "编辑",
        "edit-circle": "编辑",
        "sip": "吸管,取色器",
        "brush": "笔刷,画笔,刷子",
        "brush-2": "刷子",
        "brush-3": "刷子",
        "brush-4": "刷子",
        "paint-brush": "填色,填充,刷子",
        "contrast": "brightness,tonalit,对比度,亮度,色调",
        "contrast-2": "moon,dark,brightness,tonalit,月亮,夜间,对比度,亮度,色调",
        "drop": "water,blur,模糊,水,滴",
        "blur-off": "water,drop,slash,模糊,水,滴,禁止,关闭",
        "contrast-drop": "water,brightness,tonalit,水,对比度,亮度,色调,滴",
        "contrast-drop-2": "water,brightness,tonalit,水,对比度,亮度,色调,滴",
        "compasses": "圆规",
        "compasses-2": "圆规",
        "scissors": "剪刀,裁剪",
        "scissors-cut": "剪刀,裁剪",
        "scissors-2": "剪刀,裁剪,截屏",
        "slice": "knife,切图,切片,刀",
        "eraser": "remove formatting,橡皮,擦除,清除格式",
        "ruler": "尺子",
        "ruler-2": "尺子",
        "pencil-ruler": "design,铅笔,尺子,文具,设计",
        "pencil-ruler-2": "design,铅笔,尺子,文具,设计",
        "t-box": "文字,字体,字号",
        "input-method": "输入法,文字",
        "artboard": "grid,crop,画板,裁切",
        "artboard-2": "画板",
        "crop": "裁切",
        "crop-2": "裁切",
        "screenshot": "capture,屏幕截图,截屏",
        "screenshot-2": "capture,屏幕截图,截屏",
        "focus": "aim,target,goal,焦点,聚焦,目标,靶心",
        "focus-2": "aim,target,goal,bullseye,焦点,聚焦,目标,靶心",
        "focus-3": "aim,target,goal,bullseye,焦点,聚焦,目标,靶心",
        "paint": "填色,填充,油漆桶",
        "palette": "调色盘,色板",
        "pantone": "色板,潘通色,色号",
        "shape": "border,形状,描边,边框",
        "shape-2": "border,形状,描边,边框",
        "magic": "fantasy,magic stick,beautify,wand,魔法棒,美化,幻想,魔幻",
        "anticlockwise": "rotate,left,counterclockwise,左翻转,左旋转",
        "anticlockwise-2": "rotate,left,counterclockwise,左翻转,左旋转",
        "clockwise": "rotate,right,右翻转,右旋转",
        "clockwise-2": "rotate,right,右翻转,右旋转",
        "hammer": "tool,锤子",
        "tools": "settings,screwdriver,configuration,工具,设置",
        "drag-drop": "drag and drop,mouse,拖拽,鼠标",
        "table": "表格",
        "table-alt": "表格",
        "layout": "布局",
        "layout-2": "collage,布局,拼贴画",
        "layout-3": "collage,布局,拼贴画",
        "layout-4": "collage,布局,拼贴画",
        "layout-5": "collage,布局,拼贴画",
        "layout-6": "collage,布局,拼贴画",
        "layout-column": "左右布局",
        "layout-row": "上下布局",
        "layout-top": "顶部布局,顶部导航",
        "layout-right": "右侧布局,右侧导航",
        "layout-bottom": "底部布局,底部导航",
        "layout-left": "左侧布局,左侧导航",
        "layout-top-2": "顶部布局,顶部导航",
        "layout-right-2": "右侧布局,右侧导航",
        "layout-bottom-2": "底部布局,底部导航",
        "layout-left-2": "左侧布局,左侧导航",
        "layout-grid": "卡片布局,网格",
        "layout-masonry": "collage,瀑布流布局,拼贴画",
        "collage": "瀑布流布局,拼贴画",
        "grid": "table,网格,表格"
    },
    "Development": {
        "bug": "虫子",
        "bug-2": "虫子",
        "code": "代码,编程",
        "code-s": "代码,编程",
        "code-s-slash": "代码,编程",
        "code-box": "代码,编程",
        "terminal-box": "code,command line,prompt,终端,代码,命令行",
        "terminal": "code,command line,prompt,终端,代码,命令行",
        "terminal-window": "code,command line,browser,app,ui,终端,代码,命令行",
        "parentheses": "code,math,小括号",
        "brackets": "code,math,中括号",
        "braces": "code,math,大括号,花括号",
        "command": "apple key,place of interest,modified key,花键,苹果键",
        "cursor": "mouse,pointer,arrow,指针,鼠标",
        "git-commit": "node,onewheel,circle line,dot line,history point,提交",
        "git-pull-request": "合并申请",
        "git-merge": "combine,unite,branch,split,fork,diverge,合并",
        "git-branch": "combine,unite,split,fork,diverge,分支",
        "git-close-pull-request": "取消,关闭",
        "git-repository": "book,bookmark,仓库",
        "git-repository-commits": "book,upload,仓库,提交",
        "git-repository-private": "lock,secure,私密仓库,私人仓库",
        "html5": "html,h5,web",
        "css3": "css,web,styles",
        "javascript": "js,脚本"
    },
    "Device": {
        "tv": "television,antenna,rabbet ears,电视",
        "tv-2": "monitor,display,screen,television,电视,显示器",
        "computer": "pc,monitor,display,screen,电脑,显示器",
        "mac": "monitor,imac,display,computer,screen,显示器",
        "macbook": "laptop,computer,笔记本",
        "cellphone": "手机,电话",
        "smartphone": "mobile,手机",
        "tablet": "平板电脑",
        "device": "设备",
        "phone": "电话",
        "instance": "box,nft,cvm,实例,盒子,虚拟机",
        "database": "storage,resource,bars,progress,数据库,存储",
        "database-2": "storage,discs,silo,platter,数据库,存储",
        "server": "服务器",
        "hard-drive": "disc,storage,硬盘,存储",
        "hard-drive-2": "disc,server,storage,硬盘,服务器,存储",
        "hard-drive-3": "disc,server,storage,硬盘,服务器,存储",
        "install": "安装",
        "uninstall": "卸载",
        "save": "floppy,保存,软盘",
        "save-2": "floppy,保存,软盘",
        "save-3": "floppy,保存,软盘",
        "sd-card": "内存卡",
        "sd-card-mini": "内存卡",
        "sim-card": "电话卡",
        "sim-card-2": "电话卡",
        "dual-sim-1": "sim card,电话卡,卡槽,双卡双待",
        "dual-sim-2": "sim card,电话卡,卡槽,双卡双待",
        "u-disk": "U盘,优盘",
        "battery": "电池",
        "battery-charge": "电池,充电",
        "battery-low": "电池,低电量",
        "battery-2": "电池",
        "battery-2-charge": "电池,充电",
        "battery-saver": "电池,省电模式",
        "battery-share": "电池共享,共享电量",
        "cast": "mirroring,投屏,无线,广播",
        "airplay": "mirroring,投屏,无线",
        "cpu": "中央处理器",
        "gradienter": "水平仪",
        "keyboard": "input,键盘,输入",
        "keyboard-box": "input,键盘,输入",
        "mouse": "鼠标",
        "sensor": "capacitor,传感器,电容器",
        "router": "wifi,signal tower,radio,station,路由器,信号塔,广播,基站,流量",
        "radar": "satellite receiver,雷达,卫星接收器,锅",
        "gamepad": "consoles,controller,游戏手柄",
        "remote-control": "controller,遥控器",
        "remote-control-2": "controller,遥控器",
        "device-recover": "恢复出厂设置",
        "hotspot": "手机热点",
        "phone-find": "找回手机",
        "phone-lock": "锁定手机",
        "rotate-lock": "锁定旋转屏幕",
        "restart": "reload,refresh,重启",
        "shut-down": "power off,关机",
        "fingerprint": "指纹",
        "fingerprint-2": "指纹",
        "barcode": "scan,扫码,条形码,条码",
        "barcode-box": "scan,扫码,条形码,条码",
        "qr-code": "二维码",
        "qr-scan": "二维码,扫描",
        "qr-scan-2": "二维码,扫描",
        "scan": "扫描",
        "scan-2": "扫描",
        "rss": "feed,subscribe,订阅",
        "gps": "signal,定位,信号",
        "base-station": "wifi,signal tower,router,cast,基站,信号塔,路由器,广播,流量",
        "bluetooth": "wireless,蓝牙,无线",
        "bluetooth-connect": "wireless,蓝牙,连接,无线",
        "wifi": "无线网",
        "wifi-off": "slash,offline,connection-fail,无线网,关闭,断网,链接失败",
        "signal-wifi": "cellular,strength,无线网,信号",
        "signal-wifi-1": "cellular,strength,无线网,信号",
        "signal-wifi-2": "cellular,strength,无线网,信号",
        "signal-wifi-3": "cellular,strength,无线网,信号",
        "signal-wifi-error": "cellular,offline,connection-fail,无线网,断网,链接失败,无信号",
        "signal-wifi-off": "cellular,slash,offline,connection-fail,无线网,关闭,断网,链接失败",
        "wireless-charging": "power,flash,无线充电,闪充",
        "dashboard-2": "仪表盘",
        "dashboard-3": "仪表盘",
        "usb": "优盘"
    },
    "Document": {
        "file": "new,paper,文件,文档,新建",
        "file-2": "new,paper,文件,文档,新建",
        "file-3": "new,paper,文件,文档,新建",
        "file-4": "new,paper,文件,文档,新建",
        "sticky-note": "new,paper,文件,文档,新建,便签纸,便利贴",
        "sticky-note-2": "new,paper,文件,文档,新建,便签纸,便利贴",
        "file-edit": "文件,文档,编辑",
        "draft": "草稿箱,文件,文档,编辑",
        "file-paper": "文件,文档,纸,谱",
        "file-paper-2": "文件,文档,纸,谱",
        "file-text": "文件,文档,文本",
        "file-list": "清单,列表",
        "file-list-2": "清单,列表",
        "file-list-3": "newspaper,清单,列表,报纸",
        "bill": "账单",
        "file-copy": "duplicate,clone,复制,克隆",
        "file-copy-2": "duplicate,clone,复制,克隆",
        "clipboard": "copy,复制,剪切板",
        "survey": "research,questionnaire,clipboard,checkboxes,form,census,调查,问卷,调研",
        "article": "newspaper,document,publisher,print,page,word,file,publication,文章,报纸",
        "newspaper": "报纸",
        "file-zip": "7z,rar,压缩包",
        "file-mark": "文件,文档,标记",
        "task": "todo,任务,待办",
        "todo": "待办",
        "book": "read,dictionary,booklet,书,阅读,字典,小册子",
        "book-mark": "read,dictionary,booklet,书,阅读,字典,小册子,书签",
        "book-2": "read,dictionary,booklet,书,阅读,字典,小册子",
        "book-3": "read,dictionary,booklet,书,阅读,字典,小册子",
        "book-open": "read,booklet,magazine,书,阅读,小册子,杂志",
        "book-read": "booklet,magazine,书,阅读,小册子,杂志",
        "contacts-book": "通讯录,联系人",
        "contacts-book-2": "通讯录,联系人",
        "contacts-book-upload": "upload,通讯录,联系人,上传",
        "booklet": "notebook,手册,笔记本,小册子",
        "file-code": "config,文件,文档,代码,脚本,配置文件",
        "file-pdf": "文件,文档",
        "file-word": "文档",
        "file-ppt": "文件,文档",
        "file-excel": "文档,表单",
        "file-word-2": "文档",
        "file-ppt-2": "文件,文档",
        "file-excel-2": "文档,表单",
        "file-hwp": "文件,文档,hangul word processor",
        "keynote": "演示文稿,幻灯片,讲演",
        "numbers": "表格",
        "pages": "website,browser,ui,blocks,prototype,文稿",
        "file-search": "文件,文档,搜索",
        "file-add": "page,document,new,plus,add,文件,文档,新建",
        "file-reduce": "page,document,subtract,delete,文件,文档,减",
        "file-settings": "page,document,cog,gear,文件,文档,设置",
        "file-upload": "page,document,文件,文档,上传",
        "file-transfer": "page,document,文件,文档,传输",
        "file-download": "page,document,文件,文档,下载",
        "file-lock": "page,document,文件,文档,锁",
        "file-chart": "page,document,report,文件,文档,柱状图,报表",
        "file-chart-2": "report,文件,文档,饼图,报表",
        "file-music": "文件,文档,音乐",
        "file-gif": "文件,文档,动图",
        "file-forbid": "文件,文档,禁用",
        "file-info": "文件,文档,信息",
        "file-warning": "page,document,paper,!,alert,important,exclamation,文件,文档,警告,提醒",
        "file-unknow": "文件,文档,未知,问号",
        "file-user": "文件,文档,用户",
        "file-shield": "protected,secured,文件,文档,盾牌,保护,安全",
        "file-shield-2": "protected,secured,文件,文档,盾牌,保护,安全",
        "file-damage": "breakdown,broken,corrupt,文件,文档,损坏,破损,破裂",
        "file-history": "record,文件,文档,记录,历史",
        "file-shred": "shredder,shred,destroy,cut,文档,销毁,碎纸机,破裂,粉碎",
        "file-cloud": "network,文件,文档,云",
        "folder": "directory,file,文件夹,目录,文档",
        "folder-2": "directory,file,文件夹,目录,文档",
        "folder-3": "directory,file,文件夹,目录,文档",
        "folder-4": "directory,file,文件夹,目录,文档",
        "folder-5": "directory,file,文件夹,目录,文档",
        "folders": "directory,file,文件夹,目录,文档,批量",
        "folder-add": "directory,file,文件夹,目录,文档,添加",
        "folder-reduce": "directory,file,remove,subtract,文件夹,目录,文档,减",
        "folder-settings": "directory,file,文件夹,目录,文档,设置",
        "folder-upload": "directory,file,文件夹,目录,文档,上传",
        "folder-transfer": "directory,file,文件夹,目录,文档,传输",
        "folder-download": "directory,file,文件夹,目录,文档,下载",
        "folder-lock": "directory,file,文件夹,目录,文档,锁",
        "folder-chart": "report,文件夹,目录,文档,柱状图,报表",
        "folder-chart-2": "report,文件夹,目录,文档,饼图,报表",
        "folder-music": "directory,file,audio,sound,文件夹,目录,文档,音乐",
        "folder-forbid": "directory,file,文件夹,目录,文档,禁用",
        "folder-info": "directory,file,文件夹,目录,文档,信息",
        "folder-warning": "!,alert,important,exclamation,alert,directory,file,文件夹,目录,文档,警告,提醒",
        "folder-unknow": "directory,file,文件夹,目录,文档,未知",
        "folder-user": "directory,file,文件夹,目录,文档,用户",
        "folder-shield": "directory,file,protected,secured,文件夹,目录,文档,保护,盾牌,安全",
        "folder-shield-2": "directory,file,protected,secured,文件夹,目录,文档,保护,盾牌,安全",
        "folder-shared": "directory,file,文件夹,目录,文档,分享",
        "folder-received": "directory,file,文件夹,目录,文档,接收",
        "folder-open": "directory,file,文件夹,目录,文档,打开",
        "folder-keyhole": "directory,encryption,file,文件夹,目录,文档,打开,加密文档",
        "folder-zip": "directory,file,文件夹,目录,文档,打开,压缩",
        "folder-history": "directory,file,record,文件夹,目录,文档,记录,历史",
        "markdown": "arrow,箭头,下"
    },
    "Editor": {
        "bold": "加粗",
        "italic": "斜体",
        "heading": "标题",
        "text": "字体",
        "font-family": "字体",
        "font-sans-serif": "衬线体",
        "font-sans": "非衬线体",
        "font-mono": "等宽字体",
        "font-color": "文字色",
        "font-size": "字号,字体大小",
        "font-size-2": "字号,字体大小",
        "underline": "下划线",
        "overline": "上划线",
        "emphasis": "着重号",
        "emphasis-cn": "着重号",
        "strikethrough": "remove formatting,del,删除线",
        "strikethrough-2": "remove formatting,del,删除线",
        "format-clear": "remove formatting,清除格式",
        "align-left": "ragged right,左对齐",
        "align-center": "居中对齐",
        "align-right": "ragged left,右对齐",
        "align-justify": "four,lines,ruled,left-right,full width,排列对齐",
        "align-top": "two arrows up,double-up,start,顶部对齐",
        "align-vertically": "centered,middle,balance,垂直对齐",
        "align-bottom": "two arrows down,double-down,end,底部对齐",
        "list-check": "check list,清单列表",
        "list-check-2": "check list,清单列表",
        "list-check-3": "check list,清单列表,问卷,task",
        "list-ordered": "numbered list,有序列表",
        "list-ordered-2": "numbered list,有序列表",
        "list-unordered": "bullet list,无序列表",
        "list-radio": "vote,投票,列表",
        "list-indefinite": "列表",
        "indent-decrease": "indent more,quote,缩进",
        "indent-increase": "indent less,quote,缩进",
        "line-height": "leading,行高",
        "text-spacing": "letter spacing,character spacing,kerning,tracking,字间距",
        "text-wrap": "return,文本换行",
        "attachment-2": "annex,paperclip,附件,曲别针",
        "link": "connection,address,联系,链接,地址",
        "link-unlink": "connection,remove address,去除链接",
        "link-m": "connection,address,联系,链接,地址",
        "link-unlink-m": "connection,remove address,去除链接",
        "separator": "分割线",
        "space": "空格",
        "page-separator": "insert,分页符,插入",
        "code-view": "代码视图",
        "quote-text": "引用",
        "double-quotes-l": "left,quotaion marks,双引号",
        "double-quotes-r": "right,quotaion marks,双引号",
        "single-quotes-l": "left,quotaion marks,单引号",
        "single-quotes-r": "right,quotaion marks,单引号",
        "table-2": "表格",
        "subscript": "角标,下标,脚注",
        "subscript-2": "角标,下标,脚注",
        "superscript": "角标,上标",
        "superscript-2": "角标,上标",
        "paragraph": "¶,pilcrow,段落",
        "text-direction-l": "文本左对齐",
        "text-direction-r": "文本左对齐",
        "functions": "Σ,sum,sigma,功能",
        "omega": "Ω,horseshoe,特殊符号",
        "hashtag": "#,pound,number,井号",
        "asterisk": "*,all,everything,wildcard,星号",
        "question-mark": "?,问号",
        "translate": "translator,翻译",
        "translate-2": "translator,翻译",
        "a-b": "a/b testing,ab testing,split test,either-or,ab测试",
        "english-input": "英文输入法",
        "pinyin-input": "拼音输入法",
        "wubi-input": "五笔输入法",
        "input-cursor-move": "移动输入光标",
        "number-1": "1,一,数字",
        "number-2": "2,二,数字",
        "number-3": "3,三,数字",
        "number-4": "4,四,数字",
        "number-5": "5,五,数字",
        "number-6": "6,六,数字",
        "number-7": "7,七,数字",
        "number-8": "8,八,数字",
        "number-9": "9,九,数字",
        "number-0": "0,零,数字",
        "sort-asc": "ranking,ordering,sorting,ascending,descending,升序排列,排序",
        "sort-desc": "ranking,ordering,降序排列,排序",
        "bring-forward": "arrange,层级,向上一层",
        "send-backward": "arrange,层级,向下一层",
        "bring-to-front": "arrange,层级,移到最前面",
        "send-to-back": "arrange,层级,移到最后面",
        "h-1": "heading,一级标题",
        "h-2": "heading,一级标题",
        "h-3": "heading,一级标题",
        "h-4": "heading,一级标题",
        "h-5": "heading,一级标题",
        "h-6": "heading,一级标题",
        "insert-column-left": "添加列,左侧",
        "insert-column-right": "添加列,右侧",
        "insert-row-top": "添加行,顶部",
        "insert-row-bottom": "添加行,底部",
        "delete-column": "删除列,底部",
        "delete-row": "添加行,底部",
        "merge-cells-horizontal": "合并单元格",
        "merge-cells-vertical": "合并单元格",
        "split-cells-horizontal": "拆分单元格",
        "split-cells-vertical": "拆分单元格",
        "flow-chart": "流程图",
        "mind-map": "mindmap,脑图,思维导图",
        "node-tree": "节点,层级关系图",
        "organization-chart": "组织架构图,局域网",
        "rounded-corner": "圆角",
        "sketching": "signature,手写,手绘,签名",
        "draggable": "move,reorder,拖拽,排序",
        "slash-commands": "",
        "ai-generate": "AI自动生成"
    },
    "Finance": {
        "wallet": "pocketbook,钱包,卡包",
        "wallet-2": "money clip,钱包,卡包",
        "wallet-3": "money clip,钱包,卡包",
        "bank-card": "credit,purchase,payment,cc,银行卡,信用卡,购买,消费,支付",
        "bank-card-2": "credit,purchase,payment,cc,银行卡,信用卡,购买,消费,支付",
        "secure-payment": "credit,purchase,payment,cc,银行卡,信用卡,购买,消费,支付,安全",
        "refund": "credit card,repayment,cc,银行卡,信用卡还款",
        "refund-2": "credit card,repayment,cc,银行卡,信用卡还款",
        "safe": "vault,secure,保险柜,保险箱",
        "safe-2": "vault,insecure,unlocked,保险柜,保险箱",
        "price-tag": "label,标签,价签",
        "price-tag-2": "label,标签,价签",
        "price-tag-3": "label,标签,价签",
        "ticket": "coupon,票,优惠券,代金券",
        "ticket-2": "coupon,票,优惠券,代金券",
        "coupon": "ticket,票,优惠券,代金券",
        "coupon-2": "ticket,票,优惠券,代金券",
        "coupon-3": "ticket,票,优惠券,代金券",
        "coupon-4": "优惠券,代金券",
        "coupon-5": "优惠券,代金券",
        "shopping-bag": "purse,购物袋,购买,消费,商城",
        "shopping-bag-2": "购物袋,购买,消费,商城",
        "shopping-bag-3": "购物袋,购买,消费,商城",
        "shopping-basket": "购物篮,购买,消费,商城",
        "shopping-basket-2": "购物篮,购买,消费,商城",
        "shopping-cart": "购物车,购买,消费,商城",
        "shopping-cart-2": "购物车,购买,消费,商城",
        "vip": "会员",
        "vip-crown": "king,queen,皇冠,会员,国王,女王,王后",
        "vip-crown-2": "king,queen,皇冠,会员,国王,女王,王后",
        "vip-diamond": "钻石,会员",
        "trophy": "奖品,奖杯,金杯",
        "exchange": "swap,交换,换算,兑换",
        "exchange-box": "swap,交换,换算,兑换",
        "swap": "exchange,交换,换算,兑换",
        "swap-box": "exchange,交换,换算,兑换",
        "exchange-dollar": "swap,transfer,交换,换算,兑换,美元,转账",
        "exchange-cny": "swap,transfer,交换,换算,兑换,人民币,转账",
        "exchange-funds": "swap,transfer,交换,换算,兑换,基金,股票,转账",
        "increase-decrease": "计算器",
        "percent": "百分之,百分比",
        "copper-coin": "currency,payment,铜币,硬币,货币,钱,支付",
        "copper-diamond": "currency,coins,金币,钻石,货币,钱,支付",
        "money-cny-box": "currency,payment,货币,钱,支付,人民币",
        "money-cny-circle": "currency,coins,金币,payment,货币,钱,支付,人民币",
        "money-dollar-box": "currency,payment,货币,钱,支付,美元",
        "money-dollar-circle": "currency,coins,金币,payment,cent,penny,货币,钱,支付,美元,美分,便士",
        "money-euro-box": "currency,payment,货币,钱,支付,欧元",
        "money-euro-circle": "currency,coins,金币,payment,货币,钱,支付,欧元",
        "money-pound-box": "currency,payment,货币,钱,支付,英镑",
        "money-pound-circle": "currency,coins,金币,payment,货币,钱,支付,英镑",
        "bit-coin": "currency,payment,货币,钱,比特币",
        "coin": "金币,硬币",
        "cash": "现金,钞票",
        "coins": "金币,硬币",
        "currency": "cash,payment,货币,钱",
        "funds": "foundation,stock,基金,股票",
        "funds-box": "foundation,stock,基金,股票",
        "red-packet": "红包",
        "water-flash": "水电费",
        "stock": "股票",
        "auction": "hammer,gavel,拍卖,锤子",
        "gift": "present,礼物",
        "gift-2": "present,礼物",
        "hand-coin": "donate,business,捐赠",
        "hand-heart": "help,donate,volunteer,welfare,帮助,爱心,捐赠,志愿者,公益",
        "24-hours": "last,24小时营业",
        "nft": "token",
        "p2p": "trade,点对点,交易",
        "token-swap": "代币,兑换"
    },
    "Health & Medical": {
        "heart": "like,love,favorite,心,喜欢,爱,收藏",
        "heart-2": "like,love,favorite,心,喜欢,爱,收藏",
        "heart-3": "like,love,favorite,心,喜欢,爱,收藏",
        "heart-add": "like,love,favorite,心,喜欢,爱,收藏",
        "dislike": "like,love,remove favorite,心,不喜欢,取消收藏",
        "hearts": "romance,爱情,浪漫,心",
        "heart-pulse": "heart rate,脉搏,心率",
        "pulse": "wave,heart rate,脉搏,心率,波",
        "empathize": "care,heart,同理心,关爱,心",
        "nurse": "doctors,医生,护士",
        "dossier": "病例",
        "health-book": "健康手册,医疗手册",
        "first-aid-kit": "case,急救箱",
        "capsule": "medicine, 胶囊,药",
        "medicine-bottle": "药瓶",
        "flask": "testing,experimental,experiment,烧瓶,实验,试验",
        "test-tube": "testing,experimental,experiment,试管,实验,试验",
        "microscope": "testing,experimental,experiment,显微镜,实验,试验",
        "hand-sanitizer": "alcohol,酒精消毒洗手液",
        "mental-health": "心理健康",
        "psychotherapy": "心理治疗",
        "stethoscope": "听诊器",
        "syringe": "注射器",
        "thermometer": "体温计",
        "infrared-thermometer": "红外线体温计,体温枪",
        "surgical-mask": "外科医用口罩",
        "virus": "病毒",
        "lungs": "肺部",
        "rest-time": "close,休息时间,打烊",
        "zzz": "睡觉",
        "brain": "think,大脑,思考"
    },
    "Logos": {
        "alipay": "zhifubao,支付宝",
        "amazon": "亚马逊",
        "android": "applications,安卓,应用",
        "angularjs": "angular,programing framework",
        "app-store": "applications,苹果应用商店",
        "apple": "苹果",
        "baidu": "du,claw,百度,爪",
        "bard": "google,ai,magic,star,shining,sparkling,twinkle,人工智能,魔法,星星,闪烁",
        "behance": "behance",
        "bilibili": "哔哩哔哩",
        "blender": "3d",
        "bootstrap": "",
        "centos": "linux,system,系统",
        "chrome": "谷歌浏览器",
        "codepen": "代码笔",
        "copilot": "github,飞行员",
        "coreos": "linux,system,系统",
        "dingding": "钉钉",
        "discord": "game,chat",
        "disqus": "comments",
        "douban": "豆瓣",
        "dribbble": "追波",
        "drive": "google drive,谷歌云端硬盘",
        "dropbox": "多宝箱",
        "edge": "microsoft edge,edge浏览器",
        "edge-new": "microsoft-edge,浏览器",
        "evernote": "印象笔记",
        "facebook": "脸书",
        "facebook-circle": "脸书",
        "facebook-box": "脸书",
        "finder": "macintosh,仿达",
        "firefox": "火狐浏览器",
        "flickr": "",
        "flutter": "google",
        "gatsby": "gatsby",
        "github": "github",
        "gitlab": "gitlab",
        "google": "谷歌",
        "google-play": "applications,谷歌应用商店",
        "honor-of-kings": "game,王者荣耀",
        "ie": "internet explorer,浏览器",
        "instagram": "照片墙",
        "invision": "invision",
        "kakao-talk": "kakao talk,chat",
        "line": "连我",
        "linkedin": "领英",
        "linkedin-box": "领英",
        "kick": "",
        "microsoft-loop": "Doc,笔记",
        "mastercard": "bank card,银行卡",
        "mastodon": "mastodon,长毛象",
        "medium": "媒体",
        "messenger": "facebook,脸书,信使",
        "meta": "元",
        "microsoft": "windows,窗户,微软",
        "mini-program": "微信小程序",
        "netease-cloud-music": "netease cloud music,网易云音乐",
        "netflix": "网飞",
        "notion": "notes,doc,笔记,文档",
        "npmjs": "npm,nodejs",
        "openai": "人工智能",
        "openbase": "",
        "open-source": "opensource,开源",
        "opera": "欧朋浏览器",
        "patreon": "donate,money,捐赠,打赏",
        "paypal": "贝宝",
        "pinterest": "拼趣",
        "pixelfed": "photography,pixelfed",
        "playstation": "ps",
        "product-hunt": "product hunt",
        "qq": "penguin,tencent,腾讯,企鹅",
        "reactjs": "react,programing framework,facebook",
        "reddit": "reddit",
        "remixicon": "remix icon,图标",
        "safari": "safari浏览器",
        "skype": "skype",
        "slack": "slack",
        "snapchat": "ghost,色拉布,幽灵",
        "soundcloud": "声云",
        "spectrum": "spectrum",
        "spotify": "music,音乐",
        "stack-overflow": "stack overflow",
        "stackshare": "share,分享,技术栈",
        "steam": "game,store",
        "switch": "nintendo,任天堂",
        "taobao": "淘宝",
        "telegram": "telegram",
        "tiktok": "抖音",
        "trello": "trello",
        "tumblr": "汤博乐",
        "twitch": "twitch",
        "twitter": "推特",
        "ubuntu": "linux,system,系统",
        "unsplash": "photos",
        "vimeo": "视频",
        "visa": "bank card,银行卡",
        "vuejs": "vue,programing framework",
        "wechat": "微信",
        "wechat-2": "微信",
        "wechat-channels": "微信视频号",
        "wechat-pay": "微信支付",
        "weibo": "新浪微博",
        "whatsapp": "瓦次艾普",
        "windows": "microsoft,窗户,微软",
        "wordpress": "blog,博客",
        "xbox": "xbox",
        "xing": "xing",
        "youtube": "优兔,油管",
        "zcool": "zcool,站酷",
        "zhihu": "知乎"
    },
    "Map": {
        "map-pin": "location,navigation,地图,坐标,定位,导航,位置",
        "map-pin-2": "location,navigation,地图,坐标,定位,导航,位置",
        "map-pin-3": "location,navigation,地图,坐标,定位,导航,位置",
        "map-pin-4": "location,navigation,地图,坐标,定位,导航,位置",
        "map-pin-5": "location,navigation,地图,坐标,定位,导航,位置",
        "map-pin-add": "location,navigation,地图,坐标,定位,导航,位置,新增,添加",
        "map-pin-range": "location,navigation,地图,坐标,定位,导航,位置,范围",
        "map-pin-time": "location,navigation,地图,坐标,定位,导航,位置,时间",
        "map-pin-user": "location,navigation,地图,坐标,定位,导航,位置,用户",
        "pin-distance": "坐标,距离",
        "pushpin": "图钉",
        "unpin": "图钉,取消固定",
        "pushpin-2": "图钉",
        "compass": "navigation,safari,direction,discover,指南针,导航,方向,发现,探索",
        "compass-2": "navigation,direction,discover,指南针,导航,方向,发现,探索",
        "compass-3": "navigation,safari,direction,discover,指南针,导航,方向,发现,探索",
        "compass-4": "navigation,direction,discover,指南针,导航,方向,发现,探索",
        "compass-discover": "navigation,direction,指南针,导航,方向,发现,探索",
        "anchor": "锚",
        "china-railway": "中铁,铁路,火车",
        "space-ship": "太空飞船",
        "rocket": "火箭",
        "rocket-2": "space ship,火箭,太空飞船",
        "map": "navigation,travel,地图,导航,旅行",
        "map-2": "location,navigation,travel,地图,定位,导航,旅行",
        "treasure-map": "thriller,adventure,地图,藏宝图",
        "road-map": "navigation,travel,地图,导航,旅行",
        "earth": "global,union,world,language,地球,全球,联合,世界,语言",
        "globe": "earth,地球仪",
        "planet": "space,discover,星球,宇宙,探索,发现",
        "parking": "停车场",
        "parking-box": "停车场",
        "route": "path,路线",
        "guide": "path,指引,路线",
        "gas-station": "加气站,加油站",
        "charging-pile": "充电桩",
        "charging-pile-2": "充电桩",
        "car": "汽车",
        "car-washing": "汽车,洗车",
        "roadster": "car,汽车,跑车",
        "taxi": "car,出租车,汽车",
        "taxi-wifi": "car,出租车,汽车",
        "police-car": "汽车,警车",
        "bus": "大巴,巴士",
        "bus-2": "大巴,巴士",
        "bus-wifi": "大巴,巴士",
        "truck": "van,delivery,卡车,货车,运输",
        "train": "火车",
        "train-wifi": "火车",
        "subway": "地铁",
        "subway-wifi": "地铁",
        "flight-takeoff": "airplane,plane,origin,起飞,出发,始发,起点,飞机",
        "flight-land": "airplane,plane,destination,着陆,到达,抵达,终点,飞机",
        "plane": "fight,飞机,航班",
        "sailboat": "帆船",
        "ship": "轮船,航海,海运",
        "ship-2": "轮船",
        "bike": "自行车",
        "e-bike": "take out,takeaway,电动车,外卖",
        "e-bike-2": "take out,takeaway,电动车,外卖",
        "takeaway": "take out,takeaway,电动车,外卖",
        "motorbike": "摩托车",
        "caravan": "房车",
        "walk": "步行",
        "run": "奔跑,跑步",
        "riding": "bike,骑行,自行车",
        "barricade": "路障",
        "footprint": "脚印,足迹",
        "traffic-light": "交通,信号灯",
        "signal-tower": "base station,antenna,信号塔,基站,天线",
        "restaurant": "餐厅,饭店",
        "restaurant-2": "餐厅,饭店",
        "cup": "tea,coffee,杯子,咖啡,茶",
        "goblet": "cup,wine glass,高脚杯,酒杯",
        "hotel-bed": "酒店,床",
        "navigation": "gps,导航",
        "oil": "汽油,机油",
        "direction": "right,方向,右转",
        "steering": "drive,方向盘,驾车",
        "steering-2": "drive,方向盘,驾车",
        "lifebuoy": "life ring,救生圈",
        "passport": "passports,护照",
        "suitcase": "travel,旅行,行李箱",
        "suitcase-2": "travel,旅行,行李箱,拉杆箱",
        "suitcase-3": "travel,旅行,boarding case,行李箱,拉杆箱,登机箱",
        "luggage-deposit": "consignment,行李箱,行李寄存,托运",
        "luggage-cart": "行李车"
    },
    "Media": {
        "image": "picture,photo,图片,照片",
        "image-2": "picture,photo,图片,照片",
        "image-add": "picture,photo,图片,照片,添加",
        "image-edit": "picture,photo,图片,照片,编辑",
        "landscape": "picture,image,photo,图片,照片",
        "gallery": "picture,image,图片,相册",
        "gallery-upload": "picture,image,图片,相册,上传",
        "video": "视频",
        "movie": "film,video,电影,硬盘,视频",
        "movie-2": "film,video,电影,硬盘,视频",
        "film": "movie,video,影片,电影,视频",
        "clapperboard": "movie,film,场记板,电影",
        "vidicon": "video,camera,摄像机,摄影机,视频",
        "vidicon-2": "camera,摄像机,摄影机",
        "live": "video,camera,摄像机,摄影机,视频,直播",
        "video-add": "camera,摄像机,摄影机,视频,添加",
        "video-upload": "camera,摄像机,摄影机,视频,上传",
        "video-download": "camera,摄像机,摄影机,视频,下载",
        "dv": "vidicon,camera,摄像机,摄影机",
        "camera": "photo,照相机,拍照,照片",
        "camera-off": "photo,slash,照相机,拍照,照片,禁止,关闭",
        "camera-2": "photo,照相机,拍照,照片",
        "camera-3": "photo,照相机,拍照,照片",
        "camera-lens": "aperture,photo,照相机,拍照,照片,朋友圈",
        "camera-switch": "照相机,拍照,翻转",
        "polaroid": "camera,相机,宝丽来",
        "polaroid-2": "camera,相机,宝丽来",
        "phone-camera": "手机相机,手机摄像头",
        "webcam": "摄像头",
        "mv": "music video,音乐",
        "music": "音乐",
        "music-2": "音乐",
        "disc": "music,album,音乐,唱片",
        "album": "music,唱片,音乐",
        "dvd": "cd,dvd,record,光盘,刻录",
        "headphone": "music,headset,耳机,音乐",
        "radio": "收音机,电台",
        "radio-2": "收音机,电台",
        "tape": "录音,磁带",
        "mic": "record,voice,话筒,语音,录音,声音",
        "mic-2": "record,voice,话筒,语音,录音,声音",
        "mic-off": "record,voice,slash,关闭话筒,关闭语音,录音,关闭声音,静音,禁止",
        "volume-down": "trumpet,sound,speaker,音量低,喇叭,声音,扬声器",
        "volume-mute": "trumpet,sound,off,音量低,喇叭,声音,静音",
        "volume-up": "trumpet,sound,speaker,音量高,喇叭,声音,扬声器",
        "volume-vibrate": "trumpet,sound,speaker,喇叭,声音,扬声器,震动模式",
        "volume-off-vibrate": "trumpet,sound,speaker,静音,喇叭,声音,扬声器,静音模式",
        "speaker": "音响",
        "speaker-2": "音响",
        "speaker-3": "音响",
        "surround-sound": "环绕立体声",
        "broadcast": "广播",
        "notification": "bell,alarm,通知,铃铛,提醒",
        "notification-2": "bell,alarm,通知,铃铛,提醒",
        "notification-3": "bell,alarm,通知,铃铛,提醒",
        "notification-4": "bell,alarm,通知,铃铛,提醒",
        "notification-off": "bell,alarm,silent,slash,通知,铃铛,提醒,免打扰,静音,关闭,禁止",
        "play-circle": "start,播放,开始",
        "pause-circle": "暂停",
        "record-circle": "录音",
        "stop-circle": "停止",
        "eject": "推出",
        "play": "start,播放,开始",
        "pause": "暂停",
        "stop": "停止",
        "rewind": "fast,快退",
        "speed": "fast,快进",
        "skip-back": "上一曲",
        "skip-forward": "下一曲",
        "play-mini": "播放",
        "pause-mini": "暂停",
        "stop-mini": "停止",
        "rewind-mini": "fast,快退",
        "speed-mini": "fast,快进",
        "skip-back-mini": "上一曲",
        "skip-forward-mini": "下一曲",
        "replay-5": "playback,重播,回放",
        "forward-5": "skip,快进,快放,跳过",
        "replay-10": "playback,重播,回放",
        "forward-10": "skip,快进,快放,跳过",
        "replay-15": "playback,重播,回放",
        "forward-15": "skip,快进,快放,跳过",
        "replay-30": "playback,重播,回放",
        "forward-30": "skip,快进,快放,跳过",
        "memories": "回忆",
        "repeat": "swap,交换,循环播放",
        "repeat-2": "swap,交换,循环播放",
        "repeat-one": "单曲循环",
        "order-play": "顺序播放",
        "shuffle": "随机播放",
        "play-list": "播放列表",
        "play-list-2": "播放列表",
        "play-list-add": "列表,添加",
        "fullscreen": "maximize,全屏,最大化",
        "fullscreen-exit": "minimize,退出全屏,最小化",
        "equalizer": "sliders,controls,settings,filter,均衡器,控制器,设置,筛选",
        "sound-module": "sliders,controls,settings,filter,均衡器,控制器,设置,筛选",
        "rhythm": "节奏,韵律",
        "voiceprint": "声纹",
        "speed-up": "dashboard,快,加速,仪表盘",
        "slow-down": "dashboard,慢,减速,仪表盘",
        "hq": "high quality,高质量,高品质",
        "hd": "high definition,高清晰度",
        "4k": "high definition,high quality,高清晰度,高品质,超清",
        "closed-captioning": "隐藏字幕",
        "aspect-ratio": "宽高比,比例",
        "picture-in-picture": "画中画,小窗",
        "picture-in-picture-2": "画中画,小窗",
        "picture-in-picture-exit": "退出画中画,退出小窗"
    },
    "System": {
        "apps": "应用",
        "apps-2": "应用",
        "function": "layout,功能,应用,卡片布局",
        "dashboard": "仪表盘",
        "menu": "navigation,hamburger,导航,菜单,汉堡包",
        "menu-2": "navigation,hamburger,导航,菜单,汉堡包",
        "menu-3": "navigation,hamburger,导航,菜单,汉堡包",
        "menu-4": "navigation,hamburger,导航,菜单,汉堡包",
        "menu-5": "navigation,hamburger,导航,菜单,汉堡包",
        "menu-add": "navigation,hamburger,导航,菜单,汉堡包,添加",
        "menu-fold": "navigation,hamburger,导航,菜单,汉堡包,收起,展开",
        "menu-unfold": "navigation,hamburger,导航,菜单,汉堡包,收起,展开",
        "more": "ellipsis,更多,省略",
        "more-2": "ellipsis,更多,省略",
        "star": "favorite,like,mark,星星,星标,喜欢",
        "star-s": "favorite,like,mark,星星,星标,喜欢,半星",
        "star-half": "favorite,like,mark,星星,星标,喜欢",
        "star-half-s": "favorite,like,mark,星星,星标,喜欢,半星",
        "settings": "edit,gear,preferences,偏好设置,编辑,齿轮",
        "settings-2": "edit,gear,preferences,偏好设置,编辑,齿轮",
        "settings-3": "edit,gear,preferences,偏好设置,编辑,齿轮",
        "settings-4": "edit,gear,preferences,偏好设置,编辑,齿轮",
        "settings-5": "edit,gear,preferences,偏好设置,编辑,齿轮",
        "settings-6": "edit,gear,preferences,偏好设置,编辑,齿轮",
        "list-settings": "列表,设置",
        "forbid": "prohibited,slash,ban,禁止,禁用",
        "forbid-2": "prohibited,slash,ban,禁止,禁用",
        "prohibited": "forbid,lash,ban,禁止,禁用",
        "information": "信息",
        "error-warning": "!,alert,important,alert,exclamation,警告,错误",
        "question": "help,问号,帮助",
        "alert": "提醒,警告",
        "spam": "alert,垃圾邮件,警告",
        "spam-2": "alert,垃圾邮件,警告",
        "spam-3": "alert,垃圾邮件,警告",
        "checkbox-blank": "复选框,空",
        "checkbox": "复选框",
        "checkbox-indeterminate": "复选框",
        "add-box": "plus,new,复选框,添加,加号,新增",
        "checkbox-blank-circle": "复选框,空",
        "checkbox-circle": "复选框",
        "indeterminate-circle": "slash,ban,复选框,禁",
        "add-circle": "plus,new,复选框,添加,加号,新增",
        "close-circle": "cancel,remove,delete,empty,x,关闭,取消,移除,删除,清空",
        "radio-button": "单选框",
        "checkbox-multiple-blank": "复选框,空",
        "checkbox-multiple": "复选框,空",
        "check": "对勾",
        "check-double": "read,done,double-tick,双对勾,已读",
        "close": "cancel,remove,delete,empty,x,关闭,取消,移除,删除,清空",
        "add": "plus,new,添加,新增,加号",
        "subtract": "减",
        "divide": "除以",
        "equal": "sum,result,等于",
        "download": "receive,下载,接收",
        "upload": "send,上传,发送",
        "download-2": "下载",
        "upload-2": "上传",
        "download-cloud": "下载,云",
        "download-cloud-2": "下载,云",
        "upload-cloud": "上传,云",
        "upload-cloud-2": "上传,云",
        "login-box": "sign in,登录",
        "logout-box": "sign out,登出,注销",
        "logout-box-r": "sign out,登出,注销",
        "login-circle": "sign in,登录",
        "logout-circle": "sign out,登出,注销",
        "logout-circle-r": "sign out,登出,注销",
        "refresh": "reload,repeat,replace,reset,restart,synchronization,spinner,loader,update,刷新,重新加载,重复,重置,替换,重启,同步,更新",
        "shield": "safety,protect,盾牌,卫士,安全,防御",
        "shield-cross": "safety,protect,盾牌,卫士,安全,防御,闪电",
        "shield-flash": "safety,protect,盾牌,卫士,安全,防御",
        "shield-star": "safety,protect,盾牌,卫士,安全,防御,星星",
        "shield-user": "safety,protect,user protected,guarantor,盾牌,卫士,安全,防御,用户",
        "shield-keyhole": "safety,protect,guarantor,盾牌,卫士,安全,防御,钥匙孔",
        "shield-check": "safety,protect,盾牌,卫士,安全,防御",
        "delete-back": "backspace,删除,退格",
        "delete-back-2": "backspace,删除,退格",
        "delete-bin": "trash,remove,ash-bin,garbage,dustbin,uninstall,卸载,删除,垃圾桶",
        "delete-bin-2": "trash,remove,ash-bin,garbage,dustbin,uninstall,卸载,删除,垃圾桶",
        "delete-bin-3": "trash,remove,ash-bin,garbage,dustbin,uninstall,卸载,删除,垃圾桶",
        "delete-bin-4": "trash,remove,ash-bin,garbage,dustbin,uninstall,卸载,删除,垃圾桶",
        "delete-bin-5": "trash,remove,ash-bin,garbage,dustbin,uninstall,卸载,删除,垃圾桶",
        "delete-bin-6": "trash,remove,ash-bin,garbage,dustbin,uninstall,卸载,删除,垃圾桶",
        "delete-bin-7": "trash,remove,ash-bin,garbage,dustbin,uninstall,卸载,删除,垃圾桶",
        "lock": "security,password,锁子,安全,密码",
        "lock-2": "security,password,锁子,安全,密码",
        "lock-password": "security,锁子,安全,密码",
        "lock-unlock": "security,password,锁子,安全,密码",
        "eye": "watch,view,眼睛,查看",
        "eye-off": "slash,眼睛,不可见,关闭,禁止",
        "eye-2": "watch,view,眼睛,查看",
        "eye-close": "x,闭眼",
        "search": "搜索,放大镜",
        "search-2": "搜索,放大镜",
        "search-eye": "搜索,放大镜,眼睛",
        "zoom-in": "放大,放大镜",
        "zoom-out": "缩小,放大镜",
        "find-replace": "查找,搜索,替换",
        "share": "分享,转发",
        "share-box": "分享,转发",
        "share-circle": "分享,转发",
        "share-forward": "分享,转发",
        "share-forward-2": "分享,转发",
        "share-forward-box": "分享,转发",
        "side-bar": "侧边栏",
        "time": "clock,时间,时钟,钟表",
        "timer": "chronograph,stopwatch,秒表,计时器",
        "timer-2": "chronograph,stopwatch,秒表,计时器",
        "timer-flash": "chronograph,stopwatch,秒表,计时器,闪电",
        "alarm": "闹钟",
        "history": "record,recent,time machine,历史记录,最近",
        "hourglass-2": "time,history,recent,duration,沙漏,时间,历史记录,最近",
        "hourglass": "time,history,recent,duration,沙漏,时间,历史记录,最近",
        "thumb-up": "like,good,喜欢,好",
        "thumb-down": "dislike,bad,不喜欢,不好",
        "alarm-warning": "alert,report,police light,告警,举报,警灯",
        "notification-badge": "red dot,通知,小红点",
        "toggle": "switch,开关,触发器",
        "filter": "filtration,筛选,过滤",
        "filter-2": "filtration,筛选,过滤",
        "filter-3": "filtration,筛选,过滤",
        "filter-off": "filtration,clear-filter,筛选,过滤",
        "loader": "loader,spinner,ajax,waiting,delay,加载中,载入中,正在加载",
        "loader-2": "loader,spinner,ajax,waiting,delay,加载中,载入中,正在加载",
        "loader-3": "loader,spinner,ajax,waiting,delay,加载中,载入中,正在加载",
        "loader-4": "loader,spinner,ajax,waiting,delay,加载中,载入中,正在加载",
        "loader-5": "loader,spinner,ajax,waiting,delay,加载中,载入中,正在加载",
        "external-link": "外链",
        "loop-left": "reload,repeat,replace,reset,restart,synchronization,spinner,loader,update,刷新,重新加载,重复,重置,替换,重启,同步,更新",
        "loop-right": "reload,repeat,replace,reset,restart,synchronization,spinner,loader,update,刷新,重新加载,重复,重置,替换,重启,同步,更新"
    },
    "User & Faces": {
        "user": "用户,person,people",
        "user-2": "用户,person,people",
        "user-3": "用户,person,people",
        "user-4": "用户,person,people",
        "user-5": "用户,person,people",
        "user-6": "用户,person,people",
        "user-smile": "用户,微笑,person,people",
        "account-box": "用户,账户,person,people",
        "account-circle": "用户,账户,person,people",
        "account-pin-box": "用户,账户,person,people",
        "account-pin-circle": "用户,账户,person,people",
        "user-add": "用户,添加,新增,person,people",
        "user-follow": "关注,person,people",
        "user-unfollow": "用户,取消关注,person,people",
        "user-shared": "transfer,用户,我分享的,发送,person,people",
        "user-shared-2": "transfer,用户,我分享的,发送,person,people",
        "user-received": "用户,我接收的,收取,person,people",
        "user-received-2": "用户,我接收的,收取,person,people",
        "user-location": "用户,定位,person,people",
        "user-search": "用户,查找,person,people",
        "user-settings": "admin,用户,设置,管理员,person,people",
        "user-star": "用户,关注,person,people",
        "user-heart": "用户,关注,person,people",
        "admin": "admin,用户,管理员,person,people",
        "contacts": "联系人",
        "group": "team,团队,群组,person,people",
        "group-2": "team,团队,群组,person,people",
        "team": "团队,小组,群主,person,people",
        "user-voice": "用户,录音,演讲",
        "emotion": "表情,笑脸",
        "emotion-2": "表情,笑脸",
        "emotion-happy": "表情,开心",
        "emotion-normal": "表情,一般",
        "emotion-unhappy": "表情,不开心",
        "emotion-laugh": "comedy,happy,表情,大笑,笑脸,开心,喜剧",
        "emotion-sad": "drama,tears,悲剧,哭泣,泪",
        "skull": "ghost,骷髅,鬼怪",
        "skull-2": "ghost,horror,thriller,骷髅,鬼怪,恐惧,恐怖",
        "men": "gender,man,male,男人,男性",
        "women": "gender,woman,female,女人,女性",
        "travesti": "女人,女性",
        "genderless": "女人,女性",
        "open-arm": "张开双臂",
        "body-scan": "gesture recognition,body,扫描身体,体态识别,动作之别,手势识别",
        "parent": "patriarch,父母,亲子,家长",
        "robot": "mechanic,机器人",
        "aliens": "science fiction,ET,外星人,科幻小说",
        "bear-smile": "cartoon,anime,cartoon,小熊,微笑,儿童,动画片,卡通,动漫",
        "mickey": "cartoon,disney,迪士尼,米老鼠,微笑,儿童,动画片",
        "criminal": "horror,thriller,罪犯,犯罪,恐怖",
        "ghost": "horror,thriller,鬼怪,恐怖,恐惧",
        "ghost-2": "horror,鬼怪,恐怖,恐惧",
        "ghost-smile": "鬼怪,笑",
        "star-smile": "animation,动画,微笑,星星",
        "spy": "incognito mode,detective,secret,间谍,侦探,无痕模式,隐私模式"
    },
    "Weather": {
        "sun": "light mode,sunny,太阳,白天模式,晴天",
        "moon": "dark mode,night,月亮,夜间模式,月牙",
        "flashlight": "闪电",
        "cloudy": "多云",
        "cloudy-2": "多云",
        "mist": "雾气,雾霾",
        "foggy": "大雾",
        "cloud-windy": "风",
        "windy": "大风,刮风",
        "rainy": "下雨,雨天",
        "drizzle": "小雨",
        "showers": "中雨",
        "heavy-showers": "大雨",
        "thunderstorms": "雷暴,雷阵雨",
        "hail": "冰雹",
        "snowy": "下雪,雪天",
        "sun-cloudy": "晴转多云",
        "moon-cloudy": "夜间多云",
        "tornado": "龙卷风",
        "typhoon": "cyclone,tornado,龙卷风,旋风,台风",
        "haze": "阴霾,薄雾",
        "haze-2": "阴霾,薄雾",
        "sun-foggy": "薄雾",
        "moon-foggy": "薄雾",
        "moon-clear": "夜间模式,夜间无云",
        "temp-hot": "temperature,温度,高温,热",
        "temp-cold": "temperature,温度,低温,冷",
        "celsius": "temperature,温度,摄氏度",
        "fahrenheit": "temperature,温度,华氏度",
        "fire": "hot,火,热门",
        "blaze": "火灾",
        "earthquake": "地震",
        "flood": "洪水",
        "meteor": "流星,陨石",
        "rainbow": "彩虹",
        "shining": "star,magic,ai,twinkle,星星,魔法,闪烁",
        "shining-2": "star,magic,ai,twinkle,星星,魔法,闪烁",
        "sparkling": "shining,star,magic,ai,twinkle,星星,魔法,闪烁",
        "sparkling-2": "shining,star,magic,ai,twinkle,星星,魔法,闪烁"
    },
    "Others": {
        "basketball": "sports,运动,篮球",
        "bell": "cartoon,anime,doraemon,铃铛,哆啦A梦,卡通,动漫",
        "billiards": "sports,运动,台球,8",
        "boxing": "sports,运动,拳击",
        "cake": "anniversary,蛋糕",
        "cake-2": "anniversary,蛋糕",
        "cake-3": "蛋糕",
        "door-lock": "门锁",
        "door-lock-box": "门锁",
        "football": "sports,运动,足球",
        "game": "pac man,游戏,吃豆人",
        "handbag": "fashion,时尚,手提包,女包",
        "key": "password,钥匙,密码",
        "key-2": "password,钥匙,密码",
        "knife": "刀",
        "knife-blood": "crime,刀,犯罪,血,杀人",
        "lightbulb": "energy,creativity,灯泡,能源",
        "lightbulb-flash": "energy,creativity,灯泡,能源,闪电",
        "outlet": "插座",
        "outlet-2": "插座",
        "plug": "二脚插头",
        "plug-2": "三脚插头",
        "ping-pong": "sports,table tennis,运动,乒乓球",
        "reserved": "已预定",
        "shirt": "clothes,衬衫,衣服",
        "sword": "war,刀剑,战争,战斗,玄幻",
        "t-shirt": "skin,theme,T恤,皮肤,主题",
        "t-shirt-2": "skin,theme,T恤,皮肤,主题",
        "t-shirt-air": "dry,T恤,风干,烘干",
        "umbrella": "protect,雨伞,保护",
        "character-recognition": "ocr,文字识别",
        "voice-recognition": "asr,语音识别",
        "leaf": "energy,ecology,树叶,节能,环保,语音识别",
        "plant": "植物",
        "seedling": "树苗,植物",
        "recycle": "recyclable,可回收",
        "scales-2": "厨房称",
        "scales": "balance,称,天平,天秤",
        "scales-3": "balance,称,天平,天秤",
        "fridge": "refrigerator,电冰箱",
        "wheelchair": "accessbility,轮椅,可访问性,辅助功能",
        "cactus": "desertr,仙人掌,沙漠",
        "door": "门",
        "door-open": "开门",
        "door-closed": "关门",
        "graduation-cap": "cap,school,student,毕业,帽子,学校,学生",
        "box-1": "instance,盒子,实例",
        "box-2": "instance,盒子,实例",
        "box-3": "instance,3d,盒子,实例,立体",
        "candle": "蜡烛",
        "cross": "tomb,十字架,坟墓"
    }
};
var iconData = "";
var simpleIcons = ['Editor'];

Object.keys(icons).forEach(function (key) {
    if (key === "Editor") {
        iconData = iconData + '<div class="card"><div class="card-body"><h4 class="header-title">' + key + ' </h4><p class="card-title-desc mb-2">Use <code>&lt;i class="ri-bold"&gt;&lt;/i&gt;</code></p><div class="row icons-list-demo">';
        Object.keys(icons[key]).forEach(function (k) {
            iconData += '<div class="col-xl-3 col-lg-4 col-sm-6">\
                    <i class="ri-'+ k + '"></i> ri-' + k + '</div>';
        });
    } else {
        iconData = iconData + '<div class="card"><div class="card-body"><h4 class="header-title">' + key + ' </h4><p class="card-title-desc mb-2">Use <code>&lt;i class="ri-home-line"&gt;&lt;/i&gt;</code> or <code>&lt;i class="ri-home-fill"&gt;&lt;/i&gt;</code></p><div class="row icons-list-demo">';
        Object.keys(icons[key]).forEach(function (k) {
            iconData += '<div class="col-xl-3 col-lg-4 col-sm-6">\
                        <i class="ri-'+ k + '-line"></i> ri-' + k + '-line\
                    </div><div class="col-xl-3 col-lg-4 col-sm-6">\
                        <i class="ri-'+ k + '-fill"></i> ri-' + k + '-fill\
                    </div>';
        });
    }
    iconData += '</div></div></div>';
});

if (document.getElementById("icons"))
    document.getElementById("icons").innerHTML = iconData;
